import { Dispatch, SetStateAction } from 'react'
import { EuiButton, EuiSpacer } from '@elastic/eui'
import { Sensor, SensorType, getSensorTypeTranslation } from '@services/sensors'
import { Table, TableColumn } from '@components/table'

import { getOptionsByEnum } from '@utils/enums'
import { useTranslation } from 'react-i18next'

type DeviceTemplateSensorsProps = {
    sensors: Sensor[]
    setSensors: Dispatch<SetStateAction<Sensor[]>>
    setEditSensor: Dispatch<SetStateAction<Sensor | undefined>>
}

const DeviceTemplateSensors = ({
    sensors,
    setSensors,
    setEditSensor,
}: DeviceTemplateSensorsProps) => {
    const { t } = useTranslation(['common', 'sensors'])

    const columns: TableColumn[] = [
        {
            field: 'name',
            name: t('sensors:name'),
            sortable: false,
            filterable: false,
            filterType: 'text',
            filterPlaceholder: t('sensors:filter_by_name'),
        },
        {
            field: 'isEnabled',
            name: t('sensors:is_enabled'),
            renderAs: 'boolean',
            sortable: false,
            filterable: false,
            filterType: 'boolean',
            filterPlaceholder: t('sensors:filter_by_status'),
            filterOptions: [
                {
                    value: 'true',
                    label: t('common:active'),
                },
                {
                    value: 'false',
                    label: t('common:inactive'),
                },
            ],
        },
        {
            field: 'type',
            name: t('sensors:type'),
            formatter: (v: any) => <span>{getSensorTypeTranslation(v)}</span>,
            sortable: false,
            filterable: false,
            filterField: 'type',
            filterType: 'options',
            filterPlaceholder: t('sensors:filter_by_type'),
            filterOptions:
                getOptionsByEnum(SensorType).map(({ value }) => ({
                    value,
                    label: getSensorTypeTranslation(value),
                })) ?? [],
        },
        {
            field: 'action',
            renderAs: 'action',
            onEdit: (sensor: Sensor) => setEditSensor(sensor),
            onDelete: (sensor: Sensor) =>
                setSensors((prev) => prev.filter((s) => s.id !== sensor.id)),
        },
    ]

    return (
        <>
            <EuiSpacer size="s" />
            <Table<Sensor>
                items={sensors}
                totalCount={0}
                columns={columns}
                selectable
                itemSelectable={() => true}
                deleteSelectedRows={(items) =>
                    setSensors((prev) => prev.filter((s) => !items.includes(s)))
                }
                customButtons={[
                    <EuiButton
                        key="btn_new_sensor"
                        color="primary"
                        iconType="plus"
                        onClick={() => setEditSensor({} as Sensor)}
                    >
                        {t('sensors:new_sensor')}
                    </EuiButton>,
                ]}
            />
        </>
    )
}

export default DeviceTemplateSensors
