import { EuiComboBoxOptionOption } from '@elastic/eui'
import { Permissions } from '@services/auth'
import { SensorType } from '@services/sensors'
import { hasPermissions } from '@services/auth/auth.service'
import { useHasPermission } from '@hooks/auth'
import { useMemo } from 'react'

export enum GeneralOptionKey {
    AlarmCoolDown = 'Alarm_CoolDown',
}

export enum WmiOptionKey {
    Username = 'WMI_Username',
    Password = 'WMI_Password',
    AppendHostNameToUser = 'WMI_AppendHostNameToUser',
}

export enum PingOptionKey {
    PacketLossCount = 'Ping_PacketLossCount',
    TriesCount = 'Ping_TriesCount',
}

export enum WmiServiceOptionKey {
    Name = 'WMI_Service_Name',
}

export enum SshOptionKey {
    Username = 'SSH_Username',
    Password = 'SSH_Password',
}

export enum SshServiceOptionKey {
    Name = 'SSH_Service_Name',
}

export enum SnmpOptionKey {
    Version = 'SNMP_Version',
    Community = 'SNMP_Community',
    ContextName = 'SNMP_ContextName',
}

export enum FtpOptionKey {
    Username = 'FTP_Username',
    Password = 'FTP_Password',
    EnableSSL = 'FTP_EnableSSL',
}

export enum HttpOptionKey {
    Url = 'HTTP_Url',
    UseHttps = 'HTTP_UseHttps',
    PortNumber = 'HTTP_PortNumber',
    UseRest = 'HTTP_UseRest',
    RestMethod = 'HTTP_RestMethod',
    RestHeaders = 'HTTP_RestHeaders',
    RestPayload = 'HTTP_RestPayload',
}

export enum PortCheckOptionKey {
    Type = 'PortCheck_Type',
}

export enum AlarmWmiOptionKey {
    Processor = 'Alarm_WMI_Processor',
    Memory = 'Alarm_WMI_Memory',
    Disk = 'Alarm_WMI_Disk',
    DiskChannel = 'Alarm_WMI_Disk_Channel',
    Service = 'Alarm_WMI_Service',
}

export enum AlarmSshOptionKey {
    Processor = 'Alarm_SSH_Processor',
    Memory = 'Alarm_SSH_Memory',
    Disk = 'Alarm_SSH_Disk',
    DiskChannel = 'Alarm_SSH_Disk_Channel',
    Service = 'Alarm_SSH_Service',
}

export enum AlarmHttpOptionKey {
    Status = 'Alarm_HTTP_Status',
    ResponseTime = 'Alarm_HTTP_ResponseTime',
}

export enum AlarmPingOptionKey {
    Time = 'Alarm_PING_Time',
    Ttl = 'Alarm_PING_Ttl',
    Bytes = 'Alarm_PING_Bytes',
}

export enum NotificationPushOptionKey {
    Enabled = 'Notification_Push_Enabled',
    Delay = 'Notification_Push_Delay',
    MinLevel = 'Notification_Push_MinLevel',
    Subject = 'Notification_Push_Subject',
    Template = 'Notification_Push_Template',
}

export enum NotificationEmailOptionKey {
    Enabled = 'Notification_Email_Enabled',
    Delay = 'Notification_Email_Delay',
    MinLevel = 'Notification_Email_MinLevel',
    Subject = 'Notification_Email_Subject',
    Subscribers = 'Notification_Email_Subscribers',
    Template = 'Notification_Email_Template',
}

export const NOTIFICATION_PLACEHOLDERS = [
    'type',
    'status',
    'created',
    'agent',
    'device',
    'sensor',
    'exception',
    'error_limit',
    'warning_limit',
    'current_value',
]

export type OptionsType =
    | 'general'
    | 'wmi'
    | 'wmi_service'
    | 'ping'
    | 'snmp'
    | 'ftp'
    | 'http'
    | 'portcheck'
    | 'alarm_wmi'
    | 'alarm_ssh'
    | 'alarm_http'
    | 'alarm_ping'
    | 'notification_push'
    | 'notification_email'
    | 'ssh'
    | 'ssh_service'

/**
 * List of all available option keys.
 */
export const ALL_OPTION_TYPES: OptionsType[] = [
    'general',
    'ping',
    'wmi',
    'snmp',
    'ftp',
    'http',
    'portcheck',
    'ssh',
]

export const ALL_ALARM_OPTION_TYPES: OptionsType[] = [
    'alarm_wmi',
    'alarm_ssh',
    'alarm_http',
    'alarm_ping',
]

export const ALL_NOTIFICATION_OPTION_TYPES: OptionsType[] = [
    'notification_push',
    'notification_email',
]

export const ALL_ALARM_CHANNEL_OPTION_TYPES: string[] = [
    AlarmWmiOptionKey.DiskChannel,
    AlarmSshOptionKey.DiskChannel,
]

/**
 * Option inheritance sources.
 */
export type OptionsSource = 'tenant' | 'group' | 'device' | 'sensor'

export interface OptionInheritanceSource {
    id: string
    optionId: number
    name: string
    type: OptionsSource
    value?: string
}

export interface Option {
    id: number
    key:
        | GeneralOptionKey
        | WmiOptionKey
        | WmiServiceOptionKey
        | PingOptionKey
        | SnmpOptionKey
        | FtpOptionKey
        | HttpOptionKey
        | PortCheckOptionKey
        | SshOptionKey
        | SshServiceOptionKey
        | AlarmWmiOptionKey
        | AlarmSshOptionKey
        | AlarmPingOptionKey
        | NotificationPushOptionKey
        | NotificationEmailOptionKey
    value:
        | string
        | number
        | boolean
        | { warning: number; error: number }
        | EuiComboBoxOptionOption[]
        | undefined
    isSecure: boolean
    isEnabled: boolean

    isInherited: boolean
    inheritanceSource?: OptionInheritanceSource

    channel?: string
    channelOptions?: Option[]
    tenantId?: string
    groupId?: number
    deviceId?: number
    sensorId?: number
}

/**
 * Gets the options type (enum) based on the given key.
 * @param key The key of the option type.
 * @returns The options type.
 */
export const getOptionsType = (
    key: OptionsType
):
    | typeof GeneralOptionKey
    | typeof WmiOptionKey
    | typeof WmiServiceOptionKey
    | typeof PingOptionKey
    | typeof SnmpOptionKey
    | typeof FtpOptionKey
    | typeof HttpOptionKey
    | typeof PortCheckOptionKey
    | typeof SshOptionKey
    | typeof SshServiceOptionKey
    | typeof AlarmWmiOptionKey
    | typeof AlarmSshOptionKey
    | typeof AlarmHttpOptionKey
    | typeof AlarmPingOptionKey
    | typeof NotificationPushOptionKey
    | typeof NotificationEmailOptionKey => {
    switch (key) {
        case 'general':
            return GeneralOptionKey
        case 'wmi':
            return WmiOptionKey
        case 'wmi_service':
            return WmiServiceOptionKey
        case 'ping':
            return PingOptionKey
        case 'snmp':
            return SnmpOptionKey
        case 'ftp':
            return FtpOptionKey
        case 'http':
            return HttpOptionKey
        case 'portcheck':
            return PortCheckOptionKey
        case 'ssh':
            return SshOptionKey
        case 'ssh_service':
            return SshServiceOptionKey
        case 'alarm_http':
            return AlarmHttpOptionKey
        case 'alarm_wmi':
            return AlarmWmiOptionKey
        case 'alarm_ssh':
            return AlarmSshOptionKey
        case 'alarm_ping':
            return AlarmPingOptionKey
        case 'notification_push':
            return NotificationPushOptionKey
        case 'notification_email':
            return NotificationEmailOptionKey
    }
}

/**
 * Gets the available options for the given type.
 * @param key The type of options to get.
 * @returns The available options for the given type.
 */
export const getOptionsItems = (key: OptionsType) => {
    const type = getOptionsType(key)
    return Object.values(type).filter(
        (t) => ALL_ALARM_CHANNEL_OPTION_TYPES.indexOf(t) < 0
    )
}

/**
 * Builds options the available options based on the given options types and maps the values with the given option list.
 * @param type The type of the options to build.
 * @param options The options to be mapped with build list.
 * @returns The build options list.
 */
export const buildOptions = (
    type: OptionsType,
    options: Option[] | undefined
) => {
    if (!options) return []
    const items = getOptionsItems(type)

    return items.map((item) => {
        const o = options.find((o) => o.key === item)
        const shouldBeSecure =
            item == WmiOptionKey.Password ||
            item == SshOptionKey.Password ||
            item == FtpOptionKey.Password

        let isEnabled = o?.isEnabled ?? true
        if (
            item == HttpOptionKey.RestHeaders ||
            item == HttpOptionKey.RestPayload ||
            item == HttpOptionKey.RestMethod
        ) {
            isEnabled = options.some(
                (op) => op.key === HttpOptionKey.UseRest && op.value === 'true'
            )
        }

        return {
            id: o?.id ?? undefined,
            key: item,
            value: convertOptionValue(o),
            channel: o?.channel,
            channelOptions: o?.channelOptions,
            isSecure: o?.isSecure ?? shouldBeSecure,
            deviceId: o?.deviceId ?? undefined,
            groupId: o?.groupId ?? undefined,
            tenantId: o?.tenantId ?? undefined,
            sensorId: o?.sensorId ?? undefined,
            isInherited: o?.isInherited ?? true,
            inheritanceSource: o?.inheritanceSource ?? undefined,
            isEnabled,
        } as Option
    })
}

export const getOptionsTypeBySensorType = (
    sensorType: SensorType
): OptionsType[] | null => {
    switch (sensorType) {
        case SensorType.WmiDisk:
        case SensorType.WmiMemory:
        case SensorType.WmiProcessor:
            return ['wmi']
        case SensorType.Ping:
            return ['ping']
        case SensorType.WmiService:
            return ['wmi', 'wmi_service']
        case SensorType.SnmpProcessor:
            return ['snmp']
        case SensorType.Ftp:
            return ['ftp']
        case SensorType.Http:
            return ['http']
        case SensorType.PortCheck:
            return ['portcheck']
        case SensorType.SshProcessor:
        case SensorType.SshDisk:
        case SensorType.SshMemory:
            return ['ssh']
        case SensorType.SshService:
            return ['ssh', 'ssh_service']
        default:
            return null
    }
}

export const getAlarmOptionsTypeBySensorType = (
    sensorType: SensorType
): OptionsType[] | null => {
    switch (sensorType) {
        case SensorType.WmiDisk:
        case SensorType.WmiMemory:
        case SensorType.WmiProcessor:
        case SensorType.WmiService:
            return ['alarm_wmi']
        case SensorType.SshProcessor:
        case SensorType.SshDisk:
        case SensorType.SshMemory:
        case SensorType.SshService:
            return ['alarm_ssh']
        case SensorType.Ping:
            return ['alarm_ping']
        default:
            return null
    }
}

export const parseAlarmOptionRange = (
    value: string
): [number, number] | undefined => {
    const arr = value.split(',')
    if (arr.length == 2) return [Number(arr[0]), Number(arr[1])]
    if (arr.length == 1) {
        const value = Number(arr[0])
        return !isNaN(value) ? [0, value] : undefined
    }
    return
}

export const parseAlarmOptionValue = (value: string) => {
    if (!value) return undefined
    const [warning, error] = value.split(';').map(parseAlarmOptionRange)
    return { warning, error }
}

export const parseAlarmChannelOptionValue = (value: string) => {
    if (!value) return undefined
    const [w, e, name] = value.split(';')
    const [warning, error] = [w, e].map(parseAlarmOptionRange)
    return { name, warning, error }
}

export const serializeAlarmOptionValue = (value?: {
    warning?: [number, number] | undefined
    error?: [number, number] | undefined
}) => {
    const warningString = value?.warning
        ? `${value.warning[0]},${value.warning[1]}`
        : undefined

    const errorString = value?.error
        ? `${value.error[0]},${value.error[1]}`
        : undefined

    return `${warningString};${errorString}`
}

export const parseAlarmSelectOptionValue = (value: string) => {
    return value.split(',')
}

export const serializeAlarmSelectOptionValue = (
    values: EuiComboBoxOptionOption[]
) => {
    if (values && values.length > 0) return values.map((x) => x.value).join(',')

    return undefined
}

/**
 * Converts the given option value to the correct type.
 * @param option The option of the value to be converted.
 * @returns The converted value.
 */
const convertOptionValue = (option: Option | undefined) => {
    if (!option?.value) {
        return undefined
    }

    switch (option.key) {
        case FtpOptionKey.EnableSSL:
        case HttpOptionKey.UseHttps:
        case HttpOptionKey.UseRest:
            return typeof option.value === 'boolean'
                ? (option.value as boolean)
                : (option.value as string)?.toLowerCase() === 'true'
        case HttpOptionKey.RestHeaders:
            return option.value as { key: string; value: string }[]
        case AlarmSshOptionKey.Processor:
        case AlarmSshOptionKey.Disk:
        case AlarmSshOptionKey.Memory:
        case AlarmPingOptionKey.Time:
        case AlarmPingOptionKey.Ttl:
        case AlarmPingOptionKey.Bytes:
        case AlarmWmiOptionKey.Processor:
        case AlarmWmiOptionKey.Disk:
        case AlarmWmiOptionKey.Memory: {
            return typeof option.value === 'string'
                ? parseAlarmOptionValue(option.value as string)
                : option.value
        }
        case AlarmSshOptionKey.DiskChannel:
        case AlarmWmiOptionKey.DiskChannel:
            return parseAlarmChannelOptionValue(option.value as string)
        case AlarmWmiOptionKey.Service:
        case AlarmSshOptionKey.Service: {
            const stringValues =
                typeof option.value === 'string'
                    ? (option.value as string).split(';')
                    : undefined

            return stringValues
                ? {
                      warning: stringValues[0],
                      error: stringValues[1],
                  }
                : option.value
        }

        default:
            return option.value ?? ''
    }
}

export const getAvailableNotificationTypes = () => {
    const canViewUsers = useHasPermission(Permissions.user.canView)
    return useMemo(() => {
        if (hasPermissions([Permissions.user.canView]))
            return ALL_NOTIFICATION_OPTION_TYPES
        else
            return ALL_NOTIFICATION_OPTION_TYPES.filter(
                (x) => x !== 'notification_email'
            )
    }, [canViewUsers])
}
