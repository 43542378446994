import {
    ALL_ALARM_OPTION_TYPES,
    Option,
    getAvailableNotificationTypes,
} from '@services/options'
import {
    DeviceTemplateSensorDetails,
    DeviceTemplateSensors,
} from '@components/deviceTemplates'
import { EuiForm, EuiSpacer } from '@elastic/eui'
import {
    RemoteSelectionBoxField,
    SwitchField,
    TextField,
} from '@components/form'
import { Tab, Tabs } from '@components/tabs'
import { array, boolean, number, object, string } from 'yup'
import {
    useCreateDeviceMutation,
    useFetchAgentsQuery,
    useFetchCategoriesQuery,
    useFetchGroupsQuery,
    useFindDeviceQuery,
    useFindDeviceTemplateQuery,
    useUpdateDeviceMutation,
} from '@services/api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { DetailsDeviceRequest } from '@services/devices'
import { DetailsPage } from '@components/layout'
import { DeviceConfigurator } from '@components/devices'
import { Group } from '@services/groups'
import { GroupSelectionBoxTree } from '@components/groups'
import { OptionsEditor } from '@components/options'
import { Permissions } from '@services/auth'
import { Sensor } from '@services/sensors'
import { getNumberOrDefault } from '@utils/numbers'
import { mergeArraysBy } from '@utils/arrays'
import { useFormik } from 'formik'
import { useHasPermission } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

const deviceSchema = object({
    name: string().required(),
    hostName: string().required(),
    isEnabled: boolean().required(),
    agentId: number().required(),
    groupId: number().nullable().optional(),
    categoryId: number().nullable().optional(),
    options: array().optional(),
})

const DeviceDetailsPage = () => {
    const navigate = useNavigate()

    const { id } = useParams()
    const [skip, setSkip] = useState(true)
    const [searchParams] = useSearchParams([])

    const [editSensor, setEditSensor] = useState<Sensor | undefined>(undefined)
    const [selectedTemplateId, setSelectedTemplateId] = useState<
        number | undefined
    >(undefined)
    const [templateSensors, setTemplateSensors] = useState<Sensor[]>([])
    const [groupsState, setGroupsState] = useState<Group[]>([])
    const [showConfigurator, setShowConfigurator] = useState<boolean>(
        (!id || id === 'new') && searchParams.get('templateId') === null
    )

    const canViewGroups = useHasPermission(Permissions.group.canView)
    const canCreateDevices = useHasPermission(Permissions.device.canCreate)
    const canUpdateDevices = useHasPermission(Permissions.device.canUpdate)
    const canViewCategories = useHasPermission(Permissions.category.canView)

    const { data, isLoading } = useFindDeviceQuery(
        { id: getNumberOrDefault(id, 0) },
        { skip }
    )
    const { data: deviceTemplate } = useFindDeviceTemplateQuery(
        { id: getNumberOrDefault(selectedTemplateId, 0) },
        { skip: selectedTemplateId === undefined }
    )
    const { data: groups } = useFetchGroupsQuery({}, { skip: !canViewGroups })

    const [createDevice, { isLoading: isCreating }] = useCreateDeviceMutation()
    const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation()
    const { t } = useTranslation(['common', 'devices'])

    const isEditing = useMemo(() => !!data, [data])

    const isFormDisabled = useMemo(() => {
        if (isEditing && canUpdateDevices) return false
        if (!isEditing && canCreateDevices) return false

        return true
    }, [isEditing, canCreateDevices, canUpdateDevices])

    const notificationTypes = getAvailableNotificationTypes()

    const onSubmit = async (record: DetailsDeviceRequest) => {
        try {
            if (data && id)
                await updateDevice({
                    ...record,
                    host: (record as any).hostName,
                    id: +id,
                })
            else {
                const mappedSensors = templateSensors.map((sensor) => ({
                    ...sensor,
                    id: undefined,
                    deviceId: undefined,
                    device: undefined,
                }))
                const mappedOptions = record.options?.map((option) => ({
                    ...option,
                    id: undefined,
                }))

                await createDevice({
                    ...record,
                    host: (record as any).hostName,
                    options: mappedOptions?.filter(
                        (option) => !option.isInherited
                    ),
                    sensors: mappedSensors,
                } as any)
            }

            if (searchParams.get('referral') == 'network') navigate('/network')
            else navigate('/devices')
        } catch (error) {
            console.error(error)
        }
    }

    const form = useFormik<any>({
        initialValues: {
            name: '',
            hostName: undefined,
            isEnabled: true,
            agentId: 0,
            groupId: undefined,
            options: [],
        },
        validationSchema: deviceSchema,
        onSubmit,
    })

    /**
     * Handy function to set the host as name for creating a new device.
     * It will only be used when creating a new device and after entering
     * the name but before changing/adding the host.
     * @param v the entered name
     */
    const handleOnNameChange = (v: string) => {
        if (!form.touched.hostName && !!skip) {
            form.setFieldValue('hostName', v)
        }
    }

    const handleOptionsChange = (opts: Option[]) => {
        const newOptions = mergeArraysBy<Option>(
            [...((form.values.options as Option[]) || [])],
            opts,
            'key'
        )
        form.setFieldValue('options', newOptions)
    }

    const reduceFunc = (data: Group[], searchVal: string): Group[] => {
        return data.reduce((result: Group[], x) => {
            if (x.name.toLowerCase().includes(searchVal.toLowerCase()))
                result.push({
                    ...x,
                    subGroups:
                        x.subGroups && x.subGroups.length > 0
                            ? reduceFunc(x.subGroups, searchVal)
                            : x.subGroups,
                })
            else if (x.subGroups && x.subGroups.length > 0)
                result.push(...reduceFunc(x.subGroups, searchVal))

            return result
        }, [])
    }

    useEffect(() => {
        if (id && !isNaN(+id)) setSkip(false)
    }, [])

    useEffect(() => {
        if (data) form.setValues({ ...data, hostName: data.host, options: [] })
    }, [data])

    useEffect(() => {
        if (deviceTemplate) {
            form.setValues({ ...deviceTemplate, options: [] })

            if (deviceTemplate.sensors)
                setTemplateSensors(
                    deviceTemplate.sensors.map((sensor) => ({
                        ...sensor,
                        deviceId: 0,
                        device: undefined,
                        isTemplate: false,
                    }))
                )
        }
    }, [deviceTemplate])

    useEffect(() => {
        const agentId = searchParams.get('agentId')
        if (agentId) form.setFieldValue('agentId', parseInt(agentId))

        const groupId = searchParams.get('groupId')
        if (groupId) form.setFieldValue('groupId', parseInt(groupId))

        const templateId = searchParams.get('templateId')
        if (templateId) setSelectedTemplateId(parseInt(templateId))
    }, [searchParams])

    useEffect(() => {
        setGroupsState(groups?.items ?? [])
    }, [groups])

    return (
        <>
            {showConfigurator && !selectedTemplateId ? (
                <DeviceConfigurator
                    onCancel={() => navigate('..')}
                    onConfirm={(selectedTemplateId: number | undefined) => {
                        setSelectedTemplateId(selectedTemplateId)
                        setShowConfigurator(false)
                    }}
                />
            ) : (
                <DetailsPage
                    onClose={() => navigate('..')}
                    size={deviceTemplate ? 'm' : 's'}
                    title={
                        isEditing && canUpdateDevices
                            ? t('devices:edit_title')
                            : !isEditing && canCreateDevices
                              ? t('devices:create_title')
                              : t('devices:view_title')
                    }
                    loading={isLoading || isCreating || isUpdating}
                    submitLoading={isLoading || isCreating || isUpdating}
                    onSave={isFormDisabled ? undefined : form.handleSubmit}
                    onCancel={() => navigate('..')}
                >
                    <>
                        {editSensor && (
                            <DeviceTemplateSensorDetails
                                editSensor={editSensor}
                                setEditSensor={setEditSensor}
                                setSensors={setTemplateSensors}
                            />
                        )}

                        <EuiForm component="form" onSubmit={form.handleSubmit}>
                            <Tabs>
                                <Tab title={t('common:details')} id="details">
                                    <>
                                        <EuiSpacer />
                                        <TextField
                                            form={form}
                                            name="name"
                                            fullWidth
                                            label={t('devices:name')}
                                            placeholder={t('devices:name')}
                                            onChange={handleOnNameChange}
                                            isDisabled={isFormDisabled}
                                        />
                                        <TextField
                                            form={form}
                                            name="hostName"
                                            fullWidth
                                            label={t('devices:host')}
                                            placeholder={t('devices:host')}
                                            isDisabled={isFormDisabled}
                                        />
                                        <RemoteSelectionBoxField
                                            form={form}
                                            hook={useFetchAgentsQuery}
                                            config={{
                                                search: { field: 'name' },
                                            }}
                                            name="agentId"
                                            label={t('devices:agent')}
                                            fullWidth
                                            isClearable={false}
                                            value={form.values.agentId}
                                            isDisabled={isFormDisabled}
                                            onChange={(value) =>
                                                form.setFieldValue(
                                                    'agentId',
                                                    value[0]?.value
                                                )
                                            }
                                        />

                                        {canViewGroups && (
                                            <GroupSelectionBoxTree
                                                form={form}
                                                name="groupId"
                                                label={t('devices:group')}
                                                fullWidth
                                                value={form.values.groupId}
                                                onChange={(value) => {
                                                    form.setFieldValue(
                                                        'groupId',
                                                        value
                                                    )
                                                }}
                                                options={groupsState}
                                                onSearch={(searchVal) => {
                                                    if (searchVal !== '') {
                                                        if (groups?.items) {
                                                            const filteredGroups =
                                                                reduceFunc(
                                                                    groups.items,
                                                                    searchVal
                                                                )
                                                            setGroupsState(
                                                                filteredGroups
                                                            )
                                                        }
                                                    } else {
                                                        setGroupsState(
                                                            groups?.items ?? []
                                                        )
                                                    }
                                                }}
                                                isClearable={false}
                                                isDisabled={isFormDisabled}
                                            />
                                        )}
                                        {canViewCategories && (
                                            <RemoteSelectionBoxField
                                                form={form}
                                                hook={useFetchCategoriesQuery}
                                                config={{
                                                    search: { field: 'name' },
                                                }}
                                                name="categoryId"
                                                label={t('devices:category')}
                                                fullWidth
                                                isClearable={false}
                                                value={form.values.categoryId}
                                                isDisabled={isFormDisabled}
                                                onChange={(value) =>
                                                    form.setFieldValue(
                                                        'categoryId',
                                                        value[0]?.value
                                                    )
                                                }
                                            />
                                        )}
                                        <SwitchField
                                            form={form}
                                            name="isEnabled"
                                            label={t('devices:is_enabled')}
                                            value={form.values.isEnabled}
                                            isDisabled={isFormDisabled}
                                            onChange={(value) =>
                                                form.setFieldValue(
                                                    'isEnabled',
                                                    value
                                                )
                                            }
                                        />
                                    </>
                                </Tab>
                                <Tab title={t('common:settings')} id="settings">
                                    <OptionsEditor
                                        source="device"
                                        sourceId={selectedTemplateId || id}
                                        onChange={handleOptionsChange}
                                        isDisabled={isFormDisabled}
                                    />
                                </Tab>
                                <Tab title={t('common:alarms')} id="alarms">
                                    <OptionsEditor
                                        source="device"
                                        sourceId={selectedTemplateId || id}
                                        optionsGroups={ALL_ALARM_OPTION_TYPES}
                                        onChange={handleOptionsChange}
                                        isDisabled={isFormDisabled}
                                    />
                                </Tab>
                                <Tab title={t('common:notifications')}>
                                    <OptionsEditor
                                        source="device"
                                        sourceId={selectedTemplateId || id}
                                        optionsGroups={notificationTypes}
                                        onChange={handleOptionsChange}
                                        isDisabled={isFormDisabled}
                                    />
                                </Tab>
                                <Tab title="Sensors" hide={!deviceTemplate}>
                                    <DeviceTemplateSensors
                                        sensors={templateSensors}
                                        setSensors={setTemplateSensors}
                                        setEditSensor={setEditSensor}
                                    />
                                </Tab>
                            </Tabs>
                        </EuiForm>
                    </>
                </DetailsPage>
            )}
        </>
    )
}
export default DeviceDetailsPage
