import {
    ALL_NOTIFICATION_OPTION_TYPES,
    Option,
    getAlarmOptionsTypeBySensorType,
    getOptionsTypeBySensorType,
} from '@services/options'
import {
    DetailsSensorRequest,
    Sensor,
    SensorType,
    getSensorTypeTranslation,
} from '@services/sensors'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { EuiForm, EuiSpacer } from '@elastic/eui'
import { SelectionBoxField, SwitchField, TextField } from '@components/form'
import { Tab, Tabs } from '@components/tabs'
import { array, boolean, number, object, string } from 'yup'
import {
    useFetchCategoriesQuery,
    useFindDeviceTemplateQuery,
} from '@services/api'

import { DetailsPage } from '@components/layout'
import { OptionsEditor } from '@components/options'
import { getNumberOrDefault } from '@utils/numbers'
import { getOptionsByEnum } from '@utils/enums'
import { mergeArraysBy } from '@utils/arrays'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type DeviceTemplateSensorDetailsProps = {
    editSensor: Sensor | undefined
    setEditSensor: Dispatch<SetStateAction<Sensor | undefined>>
    setSensors: Dispatch<SetStateAction<Sensor[]>>
}

const deviceTemplateSensorSchema = object({
    name: string(),
    templateName: string().nullable(),
    type: string(),
    isEnabled: boolean().required(),
    deviceId: number().nullable().optional(),
    categoryId: number().nullable().optional(),
    options: array().optional(),
})

const DeviceTemplateSensorDetails = ({
    editSensor,
    setEditSensor,
    setSensors,
}: DeviceTemplateSensorDetailsProps) => {
    const { id } = useParams()
    const { t } = useTranslation(['common', 'sensors'])

    // States
    const [autoNameParams, setAutoNameParams] = useState<any>({
        deviceName: '',
        sensorType: SensorType.Ping,
    })

    // Queries & Mutations
    const { data: categories } = useFetchCategoriesQuery({})
    const { data: deviceTemplate } = useFindDeviceTemplateQuery(
        { id: getNumberOrDefault(id, 0) },
        { skip: !id || id === 'new' }
    )

    const onSubmit = async (record: DetailsSensorRequest) => {
        if ((record as Sensor).id) {
            const recordId = (record as Sensor).id
            setSensors((prev) => {
                const newPrev = [...prev]
                const index = newPrev.findIndex((x) => recordId)
                if (index !== -1) newPrev[index] = record as Sensor
                return newPrev
            })
        } else {
            setSensors((prev) => [...prev, record as Sensor])
        }

        closeDetailsPage()
    }

    const form = useFormik<DetailsSensorRequest>({
        initialValues:
            editSensor && Object.keys(editSensor).length > 0
                ? { ...editSensor }
                : {
                      name: '',
                      templateName: '',
                      isTemplate: true,
                      isPaused: false,
                      pausedUntil: undefined,
                      type: SensorType.Ping,
                      isEnabled: true,
                      deviceId: undefined,
                      options: [],
                  },
        validationSchema: deviceTemplateSensorSchema,
        onSubmit,
    })

    const handleOptionsChange = (opts: Option[]) => {
        const newOptions = mergeArraysBy<Option>(
            [...((form.values.options as Option[]) || [])],
            opts,
            'key'
        )
        form.setFieldValue('options', newOptions)
    }

    const optionsType = useMemo(
        () => getOptionsTypeBySensorType(form.values.type),
        [form.values.type]
    )

    const alarmOptionsTypes = useMemo(
        () => getAlarmOptionsTypeBySensorType(form.values.type),
        [form.values.type]
    )

    const closeDetailsPage = () => {
        if (editSensor) setEditSensor(undefined)
    }

    /**
     * Sets the name value automatically based on the device name and the sensor type.
     * This is only used when creating a new sensor before manually entering the name.
     */
    useEffect(() => {
        if (
            !form.touched.name &&
            (!editSensor || Object.keys(editSensor).length === 0)
        ) {
            const name = autoNameParams.sensorType
                ? `${autoNameParams.deviceName} ${getSensorTypeTranslation(
                      autoNameParams.sensorType
                  )}`.trim()
                : autoNameParams.deviceName
            form.setFieldValue('name', name)
        }
    }, [autoNameParams])

    useEffect(() => {
        if (deviceTemplate)
            setAutoNameParams({
                ...autoNameParams,
                deviceName: deviceTemplate.templateName,
            })
    }, [deviceTemplate])

    return (
        <DetailsPage
            onClose={closeDetailsPage}
            size="s"
            title={
                editSensor ? t('sensors:edit_title') : t('sensors:create_title')
            }
            onSave={form.handleSubmit}
            onCancel={closeDetailsPage}
        >
            <Tabs>
                <Tab title={t('common:details')} id="details">
                    <EuiSpacer />
                    <EuiForm component="form" onSubmit={form.handleSubmit}>
                        {form.values.isTemplate && (
                            <TextField
                                form={form}
                                name="templateName"
                                fullWidth
                                label={t('sensors:template_name')}
                                placeholder={t('sensors:template_name')}
                            />
                        )}
                        <SelectionBoxField
                            form={form}
                            name="type"
                            label={t('sensors:type')}
                            fullWidth
                            isClearable={false}
                            value={form.values.type}
                            onChange={(value) => {
                                form.setFieldValue('type', value[0]?.value)
                                setAutoNameParams({
                                    ...autoNameParams,
                                    sensorType: value[0]?.value,
                                })
                            }}
                            options={
                                getOptionsByEnum(SensorType).map(
                                    ({ value }) => ({
                                        value,
                                        label: getSensorTypeTranslation(value),
                                    })
                                ) ?? []
                            }
                        />
                        <TextField
                            form={form}
                            name="name"
                            fullWidth
                            label={t('sensors:name')}
                            placeholder={t('sensors:name')}
                        />

                        <SelectionBoxField
                            form={form}
                            name="categoryId"
                            label={t('sensors:category')}
                            fullWidth
                            isClearable={false}
                            value={form.values.categoryId}
                            onChange={(value) =>
                                form.setFieldValue(
                                    'categoryId',
                                    value[0]?.value
                                )
                            }
                            options={
                                categories?.items?.map((item) => ({
                                    label: item.name,
                                    value: item.id,
                                })) ?? []
                            }
                        />
                        <SwitchField
                            form={form}
                            name="isEnabled"
                            label={t('sensors:is_enabled')}
                            value={form.values.isEnabled}
                            onChange={(value) =>
                                form.setFieldValue('isEnabled', value)
                            }
                        />
                    </EuiForm>
                </Tab>
                <Tab
                    title={t('common:settings')}
                    id="settings"
                    hide={!optionsType ? true : undefined}
                >
                    <OptionsEditor
                        source="sensor"
                        sourceId={editSensor?.id}
                        optionsGroups={optionsType ? optionsType : []}
                        onChange={handleOptionsChange}
                    />
                </Tab>
                <Tab title={t('common:alarms')}>
                    <OptionsEditor
                        source="sensor"
                        sourceId={editSensor?.id}
                        optionsGroups={
                            alarmOptionsTypes ? alarmOptionsTypes : []
                        }
                        onChange={handleOptionsChange}
                    />
                </Tab>
                <Tab title={t('common:notifications')}>
                    <OptionsEditor
                        source="sensor"
                        sourceId={editSensor?.id}
                        optionsGroups={ALL_NOTIFICATION_OPTION_TYPES}
                        onChange={handleOptionsChange}
                    />
                </Tab>
            </Tabs>
        </DetailsPage>
    )
}

export default DeviceTemplateSensorDetails
