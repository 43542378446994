import { EuiFlexGrid, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'
import NetworkLineChart, { ChartLine, ChartProps } from './NetworkLineChart'
import { formatTimestamp, getDataDomainByQuery } from '@services/data'
import { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

type TrafficChart = {
    key: number
    title: string
    lines: ChartLine[]
    maxBandwidth: number
}

type SnmpTrafficChartProps = ChartProps

const SnmpTrafficChart = ({ data, query, setQuery }: SnmpTrafficChartProps) => {
    const [charts, setCharts] = useState<TrafficChart[]>([])
    const { t } = useTranslation(['common', 'network'])
    const xAxisDomain = useMemo(() => getDataDomainByQuery(query), [query])

    useEffect(() => {
        if (data && data.length > 0) {
            const numOfInterfaces = parseInt(
                (data as any[]).find(
                    (d) => 'snmpTrafficInterfaceNumberOfInterfaces' in d
                )?.['snmpTrafficInterfaceNumberOfInterfaces'] ?? 0
            )

            const charts = []
            for (let index = 0; index < numOfInterfaces; index++) {
                const chartName = (data as any[]).find(
                    (d) => `snmpTrafficInterfaceName_${index}` in d
                )?.[`snmpTrafficInterfaceName_${index}`]

                if (!chartName) continue

                const chart: TrafficChart = {
                    key: index,
                    title: (data as any[]).find(
                        (d) => `snmpTrafficInterfaceName_${index}` in d
                    )?.[`snmpTrafficInterfaceName_${index}`],
                    lines: [
                        {
                            key: `snmpTrafficInterfaceOutgoingBandwidthMbps_${index}`,
                            label: t(
                                'network:data_key_snmp_traffic_interface_outgoing_bandwidth_mbps'
                            ),
                        },
                        {
                            key: `snmpTrafficInterfaceIncomingBandwidthMbps_${index}`,
                            label: t(
                                'network:data_key_snmp_traffic_interface_incoming_bandwidth_mbps'
                            ),
                        },
                        {
                            key: `snmpTrafficInterfaceTotalBandwithMbps_${index}`,
                            label: t(
                                'network:data_key_snmp_traffic_interface_total_bandwith_mbps'
                            ),
                        },
                        {
                            key: `snmpTrafficInterfaceMaximumBandwidthMbps_${index}`,
                            label: t(
                                'network:data_key_snmp_traffic_interface_maximum_bandwidth_mbps'
                            ),
                        },
                    ],
                    maxBandwidth: parseInt(
                        (data as any[]).find(
                            (d) =>
                                `snmpTrafficInterfaceMaximumBandwidthMbps_${index}` in
                                d
                        )?.[
                            `snmpTrafficInterfaceMaximumBandwidthMbps_${index}`
                        ] || 100
                    ),
                }

                charts.push(chart)
            }

            setCharts(charts)
        }
    }, [data])

    return (
        <>
            <EuiFlexGrid columns={2}>
                {charts.map((chart) => (
                    <EuiFlexItem key={`chart_${chart.title}`}>
                        <EuiFlexGrid direction="row">
                            <EuiFlexItem>
                                <EuiText>{`${t('common:interface')}: ${chart.title}`}</EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <NetworkLineChart
                                    data={data}
                                    query={query}
                                    setQuery={setQuery}
                                    lines={chart.lines}
                                    xAxis="timestamp"
                                    xAxisFormatter={(v) =>
                                        formatTimestamp(v, query)
                                    }
                                    yAxisFormatter={(v) => `${v} Mbps`}
                                    xAxisDomain={xAxisDomain}
                                    yAxisDomain={[0, chart.maxBandwidth]}
                                    height={250}
                                />
                            </EuiFlexItem>
                        </EuiFlexGrid>
                        <EuiSpacer />
                    </EuiFlexItem>
                ))}
            </EuiFlexGrid>
        </>
    )
}

export default SnmpTrafficChart
