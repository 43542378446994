import { DetailsTreeNode, getNodeIcon } from '@services/network'
import { ReactElement, useCallback, useMemo } from 'react'

import { Agent } from '@services/agents'
import { Device } from '@services/devices'
import { EuiContextMenuItem } from '@elastic/eui'
import { Group } from '@services/groups'
import { Sensor } from '@services/sensors'
import { useAppSelector } from '@hooks/store'
import { useHasDependentPermissions } from '@hooks/auth'
import { useTranslation } from 'react-i18next'

export type ItemType = DetailsTreeNode
export type Item = Sensor | Device | Group | Agent

export type ActionType =
    | 'details'
    | 'delete'
    | 'edit'
    | 'add-sub-group'
    | 'add-device'
    | 'add-sensor'
    | 'install'
    | 'pause'
    | 'resume'

type OnClickHandler = (
    item: Item,
    itemType: ItemType,
    action: ActionType
) => void

type ContextMenuItem = {
    type: ItemType
    item: Item
    action: ActionType
    onClick: OnClickHandler
    icon: string
    title: string
    danger?: boolean
    disabled?: boolean
}

const generateContextMenuItem = (options: ContextMenuItem) => (
    <EuiContextMenuItem
        key={`${options.type}-${options.action}-${options.item.id}`}
        icon={options.icon}
        disabled={options.disabled}
        onClick={() =>
            options.onClick(options.item, options.type, options.action)
        }
    >
        {options.title}
    </EuiContextMenuItem>
)

const useContextMenuItems = () => {
    const { t } = useTranslation(['common', 'network'])
    const { expandedKeys } = useAppSelector((state) => state.network.treeParams)

    // Sensor permissions
    const canPauseSensor = useHasDependentPermissions('sensor', ['canPause'])
    const canViewSensor = useHasDependentPermissions('sensor', ['canView'])
    const canDeleteSensor = useHasDependentPermissions('sensor', ['canDelete'])
    const canCreateSensor = useHasDependentPermissions('sensor', [
        'canView',
        'canCreate',
    ])

    // Device permissions
    const canViewDevice = useHasDependentPermissions('device', ['canView'])
    const canCreateDevice = useHasDependentPermissions('device', [
        'canView',
        'canCreate',
    ])
    const canDeleteDevice = useHasDependentPermissions('device', ['canDelete'])

    // Group permissions
    const canEditGroup = useHasDependentPermissions('group', [
        'canView',
        'canUpdate',
    ])
    const canCreateGroup = useHasDependentPermissions('group', [
        'canView',
        'canCreate',
    ])
    const canDeleteGroup = useHasDependentPermissions('group', ['canDelete'])

    // Agent permissions
    const canViewAgent = useHasDependentPermissions('agent', ['canView'])
    const canDeleteAgent = useHasDependentPermissions('agent', ['canDelete'])

    const baseSensorMenu = useMemo(
        () => [
            {
                action: 'pause',
                disabled: !canPauseSensor,
            },
            {
                action: 'details',
                icon: 'eye',
                title: t('common:details'),
                disabled: !canViewSensor,
            },
            {
                action: 'delete',
                icon: 'trash',
                title: t('common:delete'),
                danger: true,
                disabled: !canDeleteSensor,
            },
        ],
        [canPauseSensor, canViewSensor, canDeleteSensor]
    )

    const baseDeviceMenu = useMemo(
        () => [
            {
                action: 'add-sensor',
                icon: 'plus',
                title: t('common:add-sensor'),
                disabled: !canCreateSensor,
            },
            {
                action: 'details',
                icon: 'eye',
                title: t('common:details'),
                disabled: !canViewDevice,
            },
            {
                action: 'delete',
                icon: 'trash',
                title: t('common:delete'),
                danger: true,
                disabled: !canDeleteDevice,
            },
        ],
        [canCreateSensor, canViewDevice, canDeleteDevice]
    )

    const baseGroupMenu = useMemo(
        () => [
            {
                action: 'toggleExpandAll',
            },
            {
                action: 'edit',
                icon: 'pencil',
                title: t('common:edit'),
                disabled: !canEditGroup,
            },
            {
                action: 'add-sub-group',
                icon: 'plus',
                title: t('common:add-sub-group'),
                disabled: !canCreateGroup,
            },
            {
                action: 'add-device',
                icon: 'plus',
                title: t('common:add-device'),
                disabled: !canCreateDevice,
            },
            {
                action: 'delete',
                icon: 'trash',
                title: t('common:delete'),
                danger: true,
                disabled: !canDeleteGroup,
            },
        ],
        [canEditGroup, canCreateGroup, canCreateDevice, canDeleteGroup]
    )

    const baseAgentMenu = useMemo(
        () => [
            {
                action: 'toggleExpandAll',
            },
            {
                action: 'details',
                icon: 'eye',
                title: t('common:details'),
                disabled: !canViewAgent,
            },
            {
                action: 'install',
                icon: 'download',
                title: t('common:install'),
            },
            {
                action: 'add-device',
                icon: 'plus',
                title: t('common:add-device'),
                disabled: !canCreateDevice,
            },
            {
                action: 'delete',
                icon: 'trash',
                title: t('common:delete'),
                danger: true,
                disabled: !canDeleteAgent,
            },
        ],
        [canViewAgent, canCreateDevice, canDeleteAgent]
    )

    const getContextMenuItems = useCallback(
        (item: Item, type: ItemType, onClick: OnClickHandler) => {
            let menuItems: ReactElement[] = []
            const currentItemIsExpanded =
                expandedKeys.indexOf(`${type}_${item.id}`) !== -1

            if (type === 'sensor')
                menuItems = baseSensorMenu
                    .map((x) =>
                        x.action === 'resume' || x.action === 'pause'
                            ? {
                                  type: type,
                                  item: item,
                                  action: (item as Sensor).isPaused
                                      ? 'resume'
                                      : 'pause',
                                  onClick: onClick,
                                  icon: (item as Sensor).isPaused
                                      ? 'play'
                                      : 'pause',
                                  title: (item as Sensor).isPaused
                                      ? t('common:resume')
                                      : t('common:pause'),
                                  disabled: !canPauseSensor,
                              }
                            : { ...x, type, item, onClick }
                    )
                    .map((x) => generateContextMenuItem(x as ContextMenuItem))

            if (type === 'device')
                menuItems = baseDeviceMenu
                    .map((x) => ({
                        ...x,
                        type,
                        item,
                        onClick,
                    }))
                    .map((x) => generateContextMenuItem(x as ContextMenuItem))

            if (type === 'group')
                menuItems = baseGroupMenu
                    .map((x) =>
                        x.action === 'toggleExpandAll'
                            ? {
                                  ...x,
                                  icon: currentItemIsExpanded
                                      ? 'minimize'
                                      : 'expand',
                                  title: currentItemIsExpanded
                                      ? t('common:collapse_all')
                                      : t('common:expand_all'),
                                  type,
                                  item,
                                  onClick,
                              }
                            : {
                                  ...x,
                                  type,
                                  item,
                                  onClick,
                              }
                    )
                    .map((x) => generateContextMenuItem(x as ContextMenuItem))

            if (type === 'agent')
                menuItems = baseAgentMenu
                    .map((x) =>
                        x.action === 'toggleExpandAll'
                            ? {
                                  ...x,
                                  icon: currentItemIsExpanded
                                      ? 'minimize'
                                      : 'expand',
                                  title: currentItemIsExpanded
                                      ? t('common:collapse_all')
                                      : t('common:expand_all'),
                                  type,
                                  item,
                                  onClick,
                              }
                            : {
                                  ...x,
                                  type,
                                  item,
                                  onClick,
                              }
                    )
                    .map((x) => generateContextMenuItem(x as ContextMenuItem))

            return {
                title: (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {getNodeIcon(type)}
                        {item.name}
                    </span>
                ),
                items: menuItems,
            }
        },
        [
            canPauseSensor,
            baseSensorMenu,
            baseAgentMenu,
            baseDeviceMenu,
            baseGroupMenu,
            expandedKeys,
        ]
    )

    return { getContextMenuItems }
}

export default useContextMenuItems
