import { EuiConfirmModal, EuiSpacer, EuiText } from '@elastic/eui'
import { getDefaultQuery, useFetchDevicesQuery } from '@services/api'

import { SelectionBox } from '@components/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type DeviceConfiguratorProps = {
    onConfirm: (deviceTemplateId: number | undefined) => void
    onCancel: () => void
}

const DeviceConfigurator = ({
    onConfirm,
    onCancel,
}: DeviceConfiguratorProps) => {
    const { t } = useTranslation(['common', 'deviceConfigurator'])

    // Queries
    const { data: deviceTemplates, isLoading: templatesLoading } =
        useFetchDevicesQuery({
            ...getDefaultQuery(),
            filters: ['isTemplate==true'],
        })

    // States
    const [selectedTemplateId, setSelectedTemplateId] = useState<
        number | undefined
    >(undefined)

    return (
        <EuiConfirmModal
            title={t('deviceConfigurator:title')}
            onCancel={onCancel}
            onConfirm={() => onConfirm(selectedTemplateId)}
            cancelButtonText={t('common:cancel')}
            confirmButtonText={t('common:confirm')}
            buttonColor="danger"
            defaultFocusedButton="confirm"
            isLoading={templatesLoading}
        >
            <>
                <EuiText>
                    <p>{t('deviceConfigurator:label')}</p>
                </EuiText>
                <EuiSpacer />
                <SelectionBox
                    placeholder={t('deviceConfigurator:selection_placeholder')}
                    value={selectedTemplateId}
                    onChange={(value) => setSelectedTemplateId(value[0]?.value)}
                    options={
                        deviceTemplates?.items?.map((item) => ({
                            label: item.templateName ?? item.name,
                            value: item.id,
                        })) || []
                    }
                />
            </>
        </EuiConfirmModal>
    )
}

export default DeviceConfigurator
