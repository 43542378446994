import { FC, useEffect, useMemo, useState } from 'react'
import SelectionBox, { SelectionBoxProps } from './SelectionBox'

import { useSearchQuery } from '@hooks/query'

type UseQueryArgs = Parameters<typeof useSearchQuery>

export type RemoteSelectionBoxProps = Omit<SelectionBoxProps, 'options'> & {
    hook: UseQueryArgs[0]
    config: UseQueryArgs[1]
}

const RemoteSelectionBox: FC<RemoteSelectionBoxProps> = (props) => {
    const { hook, config, value, ...selectionBoxProps } = props

    const [searchValue, setSearchValue] = useState('')
    const [selected, setSelected] = useState<
        { label: string; value: string }[]
    >([])

    const searchConfig = useMemo(() => {
        const newConfig = {
            ...config,
            search: { ...config.search, value: searchValue },
        }

        if (value) {
            newConfig.append = {
                ...newConfig.append,
                filters: [...(newConfig.append?.filters || []), `id==${value}`],
            }
        }

        return newConfig
    }, [searchValue, config, value])

    const { data, isLoading } = useSearchQuery<any>(hook, searchConfig)

    const handleOnSearchChange = (value: string) => {
        setSearchValue(value)
    }

    const handleOnChange: SelectionBoxProps['onChange'] = (value) => {
        setSelected(value)
        selectionBoxProps.onChange(value)
    }

    useEffect(() => {
        if (data?.items && value) {
            const newValue = data.items
                .filter((item) => item.id === value)
                .map((item) => ({
                    label: item.name || '',
                    value: item.id,
                }))

            setSelected(newValue)
            selectionBoxProps.onChange(newValue)
        }
    }, [value, data])

    return (
        <SelectionBox
            {...selectionBoxProps}
            options={
                data?.items?.map((item) => ({
                    label: item.name || '',
                    value: item.id,
                })) || []
            }
            loading={isLoading}
            optionsAsValue={selected}
            onChange={handleOnChange}
            onSearchChange={handleOnSearchChange}
        />
    )
}

export default RemoteSelectionBox
